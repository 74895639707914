.currency-select-item {
  cursor: pointer;
  background-color: #ffffff;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px;
  transition: 0.3s;
  border-radius: 8px;
}

.currency-select-item__text {
  margin-left: 40px;
  color: #090909;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.currency-select-item.chosen {
  background-color: red;
}

.currency-select-item__images {
  width: 30px;
}

.currency-select-item__images img:first-child {
  position: absolute;
  left: 6px;
  top: 6px;
}

.currency-select-item__images img:last-child {
  position: absolute;
  left: 18px;
  top: 6px;
  z-index: 2;
}

.currency-select-item.chosen,
.currency-select-item:hover {
  background-color: #f5f4f5;
}
