.qr-code-modal__wrapper {
  z-index: 3;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgb(9, 9, 9, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.qr-code-modal__wrapper .qr-code-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  max-width: 480px;
  width: 100%;
  padding: 40px;
  border-radius: 24px;
  border: 1px solid #f5f4f5;
  background: #fff;
  box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
}

.qr-code-modal .cross {
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 30px;
}

.qr-code-modal h4 {
  margin: 0;
  color: #090909;
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.qr-code-modal p {
  margin: 0;
  color: #47434d;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
}

.qr-code-modal .qr-image {
  margin-top: 30px;
  height: 190px;
  width: 190px;
}
