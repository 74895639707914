#footer {
  background-color: #f5f4f5;
  /* padding: 45px 0; */
  /* margin-top: 36px; */
  height: 8vh;
  justify-content: center;
  display: flex;
}

.footer__container {
  padding: 0px 72px;
}

.footer_content {
}

#footer .logo_wrap {
  width: 100%;
  padding-bottom: 30px;
}

#footer .logo {
  display: inline-block;
  max-width: 170px;
  width: 100%;
}

#footer .logo img {
  width: 100%;
  height: 100%;
}

#footer .info_body {
  /* border-top: 1px solid #CFCED3; */
  /* padding-top: 30px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  gap: 20px;
  width: 100%;
}

.info_body p {
  margin: 0;
}

@media (max-width: 767px) {
  .footer__container {
    padding: 20px 36px;
  }
}

#footer .copyright,
#footer .link {
  cursor: pointer;
  color: #807c90;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

#footer .info_link_wrap {
  display: flex;
  align-items: center;
  gap: 30px;
}

#footer .link {
  position: relative;
}

#footer .link:hover:after {
  width: 100%;
  transition: all 0.3s linear;
}

#footer .link:after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 1px;
  background-color: #47434d;
  transition: all 0.3s linear;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 768px) {
  #footer .info_body {
    display: flex !important;
    flex-direction: revert-layer;
  }
  #footer {
    height: inherit;
  }
}
