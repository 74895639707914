.file-input__container {
  border: 2px dashed #8F8E9B;
  border-radius: 10px;
}

.file-input {
  display: none;
}

.file-input__column {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  padding: 25px;
  gap: 10px;
}

.file-input__title {
  text-wrap: wrap;
  color: #47434D;
  word-wrap: normal;
  word-break: break-all;
  text-align: center;
  font-size: 18px;
}

.file-input__icon {
  max-width: 160px;
  width: 100%;
  max-height: 160px;
  width: 100%;
}

.file-input__image {
  max-width: 160px;
  width: 100%;
  max-height: 200px;
  height: 100%;
}

.file-input__button {
  margin: 0 !important;
  width: 193px;
}

.file-input__button_upload {
  cursor: pointer;
  color: #090909;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 18px;
  font-weight: 700;
  text-decoration-line: underline;
}