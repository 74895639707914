.otp-input__container {
  display: flex;
  gap: 16px;
}


input[name="otp-code"] {
  max-width: 54px;
  width: 100%;
  height: 66px;
  text-align: center;
  padding: 0;
}

.code-red {
  border: 1px solid #DE5656;
}

.code-green {
  border: 1px solid #4FB54D;
}