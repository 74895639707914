.policy {
  max-width: 789px;
  width: 100%;
}

.backButtonPrivacy {
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  color: #47434d;
  margin-bottom: 15px;
  cursor: pointer;
}

.backButtonPrivacy img {
  margin-right: 10px;
}

.policy__title {
  color: #090909;
  font-family: Inter;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 46px;
  margin: 0;
}

.policy h2 {
  margin-top: 0;
}

.policy__date {
  color: #47434d;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin: 0;
  margin-bottom: 20px;
}

.policy__rules {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.policy__rules p {
  margin: 0;
  color: #47434d;
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.policy__rules > div > ul {
  padding-left: 16px;
  margin: 0;
}

.policy__rules > div > ul > li {
  color: #47434d;
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 10px;
}

.policy__rules > div > ul > li::marker {
  content: "- ";
  color: #47434d;
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.policy__rules_main-item {
  color: #090909;
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 35px;
}

.policy__rules_item {
  color: #47434d;
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 35px;
}

.policy__cols {
  display: flex;
  gap: 10px;
}

.policy__cols .col1 {
  max-width: 123px;
  width: 100%;
}

.policy__cols .col1 p {
  color: #47434d;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.policy__cols .col2 p {
  color: #47434d;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.policy__info p {
  color: #47434d;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}
