.regHeader h2 {
  font-family: Inter;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-size: 34px;
  margin-bottom: 8px;
  line-height: 46px;
}

.regHeader p {
  font-family: Inter;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  margin: 0;
  color: #47434d;
  text-align: center;
  font-size: 14px;
  line-height: 140%;
}

.regHeader > p:last-child {
  margin-bottom: 24px;
}

.mainRegBlock {
  display: flex;
  border: 1px solid #f4f5f5;
  border-radius: 34px;
  box-shadow: 0px 5px 16px 0px #080f340f;
  min-height: 100px;
}

.regContainer {
  margin-top: 0vh;
  margin-bottom: 0px;
  min-height: 82vh;
  padding: 10px 0px;
}

.regContainer input {
  font-family: Inter;
}

.pointerBlock {
  border-right: 1px solid #e4e7e7;
  padding: 20px;
}

.pointerBlock > div {
  padding: 0vh 0vh;
}

/* Мобільна адаптація */
@media only screen and (min-device-width: 320px) and (max-device-width: 768px) {
  .regContainer {
    margin-top: 0vh;
    margin-bottom: 20px;
    height: initial;
  }

  .regHeader h2 {
    text-align: center;
    font-size: 34px;
    margin-top: 10px;
  }

  .regHeader p {
    text-align: center;
    font-size: 14px;
  }

  .mainRegBlock {
    display: block;
    border: none;
    border-radius: none;
    box-shadow: none;
    min-height: 100px;
  }

  .mobileRegBlock {
    padding: 0px;
  }

  .pointerBlock {
    border-right: none;
    padding: 20px;
    border-top: 1px solid #e5e4e7;
    border-bottom: 1px solid #e5e4e7;
  }

  .pointerBlock > div {
    padding: 0vh;
  }
}
