.indicator {
  margin-top: 12px;
  height: 4px;
  border-radius: 20px;
}

.strength-status {
  margin-left: 4px;
  color: #807c90;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}

.strength-status__value {
  align-self: flex-start;
  color: #4c4657;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}
