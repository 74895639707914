.custom-select__container {
  position: relative;
  width: 100%;
}

.custom-select__label {
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
  gap: 12px;
}

.custom-select__label > span {
  color: #090909;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.custom-select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 20px 1rem;
  border-radius: 0.75rem;
  border: 1px solid #cfced3;
  transition: 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.custom-select__text {
  margin-left: 40px;
  color: #090909;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.custom-select__placeholder {
  color: #47434d;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.imgRotate {
  transform: rotate(180deg);
}

.custom-select__images {
  width: 30px;
}

.custom-select__images_img1 {
  position: absolute;
  left: 16px;
}

.custom-select__images_img2 {
  position: absolute;
  left: 28px;
  z-index: 2;
}

.custom-select__items {
  position: absolute;
  width: 100%;
  z-index: 3;
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 10px;
  border-radius: 12px;
  border: 1px solid #f5f4f5;
  background: #fff;
  box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
}

input {
  color: var(--Design-Text-Body, #47434d);
  flex: auto;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  width: 100%;
}

.custom-select:focus-within {
  border-color: #090909;
  color: #090909;
}

.custom-select::placeholder {
  color: #807c90;
}

.custom-select[disabled],
input:disabled {
  color: #807c90;
  background: #faf9fa;
  border-color: #cfced3;
  cursor: default;

  & + .copy-data {
    position: absolute;
    top: 50%;
    right: 16px;
    width: 25px;
    height: 24px;
    transform: translate(0, -50%);
  }
}

.custom-select.error {
  border: 1px solid #de5656;
}
