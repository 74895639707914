.exchange-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}

.exchange-item__left,
.exchange-item__right {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
}

.exchange-item__images {
  width: 64px;
  height: 70px;
  position: relative;
}

.exchange-item__images img {
  position: absolute;
  width: 44px;
  height: 44px;
}

.exchange-item__images img:first-child {
  top: 0px;
}

.exchange-item__images img:last-child {
  top: 22px;
  right: 0;
}

.exchange-item__info {
  display: flex;
  flex-direction: column;
}

.exchange-item__left .exchange-item__info {
  align-items: flex-start;
}

.exchange-item__right .exchange-item__info {
  align-items: flex-end;
}

.exchange-item__info p {
  margin: 0;
}

.exchange-item__text {
  color: #807c90;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  text-transform: capitalize;
}

.exchange-item__status {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
}

.circle-status {
  border-radius: 50%;
  height: 8px;
  width: 8px;
}

.circle-status.done {
  background-color: #4fb54d;
}

.circle-status.pending {
  background-color: #ffb241;
}

.circle-status.declined {
  background-color: #de5656;
}

.circle-status.invoice {
  background-color: #3023d7;
}

.exchange-item__title {
  color: #47434d;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.left {
  align-items: flex-start;
}

.right {
  align-items: flex-end;
}

.exchange-item__right > img {
  cursor: pointer;
  height: 24px;
  width: 24px;
}
