.date-picker {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.date-picker__input__container {
  cursor: pointer;
  position: relative;
}

.date-picker__container {
  /* position: absolute; */
  z-index: 1;
  width: 100%;
  margin-top: 12px;
  border-radius: 12px;
  border: 1px solid #f5f4f5;
  background: #fff;
  box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
  padding: 16px 0px;
}

.date-picker__list {
  max-height: 258px;
  overflow: auto;
  overflow-x: hidden;
  width: 100%;
  background: #fff;
  padding: 0px 20px;
}

.date-picker__list_item {
  cursor: pointer;
  color: #47434d;
  padding: 6px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.date-picker__input__container img {
  position: absolute;
  z-index: 2;
  right: 6px;
  top: 35%;
}
.arrow-up {
  transform: rotate(180deg);
}
