.error-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin-top: 0vh;
  margin-bottom: 0px;
  min-height: 82vh;
  padding: 10px 0px;
}

.error-page__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #090909;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}

.error-page__title img {
  max-width: 165px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .error-page__title {
    font-size: 140px;
  }

  .error-page__title img {
    height: 100px;
  }
}

@media screen and (min-width: 768px) {
  .error-page__title {
    font-size: 192px;
  }

  .error-page__title img {
    height: 136px;
  }
}

.error-page__text {
  text-align: center;
  margin: 0;
  color: #807c90;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

@media screen and (max-width: 768px) {
  .error-page__text {
    font-size: 18px;
  }
}

@media screen and (min-width: 768px) {
  .error-page__text {
    font-size: 24px;
  }
}

.error-page__button {
  cursor: pointer;
  margin-top: 40px;
  border-radius: 56px;
  background: #090909;
  box-shadow: 0px 3px 12px 0px rgba(74, 58, 255, 0.18);
  border: none;

  color: #fff;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  padding: 20px 0px;
}

@media screen and (max-width: 768px) {
  .error-page__button {
    max-width: 300px;
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .error-page__button {
    max-width: 390px;
    width: 100%;
  }
}
