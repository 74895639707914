.country-input {
  position: relative;
}

.countries-list {
  max-width: 394px;
  width: 100%;
  position: absolute;
  margin-top: 12px;
  z-index: 2;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 12px;
  padding: 16px 0px;
  max-height: 264px;
  border: 1px solid #f5f4f5;
  box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
}

.countries-list__container {
  padding: 0px 16px;
  overflow: auto;
}

::-webkit-scrollbar {
  background: transparent;
  height: 2px;
  width: 2px;
}

::-webkit-scrollbar-thumb {
  background: rgba(143, 142, 155, 1);
}

::-webkit-scrollbar-track {
  background: transparent;
}

.country__container {
  cursor: pointer;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 6px;
}

.country__name {
  margin: 0;
  color: #090909;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
