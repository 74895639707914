@media screen and (min-width: 768px) {
  .loginContainer {
    height: 82vh;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

@media screen and (max-width: 768px) {
  .loginContainer {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 85vh;
  }
}

body {
  background-color: white;
}

@media screen and (max-width: 768px) {
  .mainContainer {
    height: 80vh;
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-width: 768px) {
  .mainContainer {
    height: 80vh;
    align-items: center;
    justify-content: center;
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .exchangeBlock {
    display: flex;
    font-family: Inter;
    border: 1px solid #f4f5f5;
    border-radius: 34px;
    box-shadow: 0px 5px 16px 0px #080f340f;
    min-height: 100px;
    max-width: 514px;
    width: 100%;
    padding: 35px;
  }
}

@media screen and (max-width: 768px) {
  .exchangeBlock {
    display: flex;
    padding: 20px;
  }
}

.exchangeBlock > h1 {
  font-family: Inter;
  font-size: 34px;
  font-weight: 700;
  line-height: 40.8px;
  text-align: left;
  color: #090909;
}

.loginBlock {
  font-family: Inter;
}

@media screen and (min-width: 768px) {
  .loginBlock {
    /* padding: 25px; */
    display: flex;
    border: 1px solid #f4f5f5;
    border-radius: 34px;
    box-shadow: 0px 5px 16px 0px #080f340f;
    min-height: 100px;
    max-width: 514px;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .loginBlock {
    display: flex;
    width: 100%;
  }
}

.loginBlock > .container > p {
  margin-bottom: 40px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  color: #47434d;
}

.loginTitle {
  margin: 0;
  color: #090909;
  font-family: Inter;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.loginSubtitle {
  margin: 0;
  color: #47434d;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin-top: 6px;
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  .loginBlock > .container {
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80vh;
  }

  .loginBlock > .container > div > .submitButtonLoginStyle {
    margin: 0;
    box-sizing: border-box;
  }
}

@media screen and (min-width: 768px) {
  .loginBlock > .container {
    padding: 35px;
  }
}

.inputLogin__container {
  position: relative;
}

.inputLogin__container > img {
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 24px;
}

.inputLogin > p {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  color: #090909;
  margin-bottom: 12px;
}

.inputLogin > input {
  font-family: Inter;
  width: 100%;
  height: 66px;
  border-radius: 12px;
  border: 1px solid #ceced3;
  padding-left: 20px;
  font-size: 18px;
  transition: 0.3s;
}

.login__status {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  color: #de5656;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;

  border-radius: 8px;
  background: #fceeee;
  padding: 8px 12px;
}

.login__status p {
  margin: 0;
}

.forgotPass {
  margin-top: 10px;
}

.forgotPass__text {
  margin: 0;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  text-align: right;
  color: #807c90;
  cursor: pointer;
  margin-bottom: 30px;
}

.submitButtonLoginStyle:disabled {
  border-radius: 56px;
  padding: 20px 40px;
  background-color: #f4f4f5;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  color: #ceced3;
  margin: 10px 0px;
  cursor: pointer;
  border: aliceblue;
  width: 100%;
}

.submitButtonLoginStyle {
  border-radius: 56px;
  padding: 20px 40px;
  background-color: #090909;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  margin: 10px 0px;
  cursor: pointer;
  border: aliceblue;
  box-shadow: 0px 3px 12px 0px #4343432e;
  width: 100%;
}

.submitButtonSignupStyle {
  border-radius: 56px;
  padding: 20px 40px;
  background-color: #fff;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  color: #090909;
  margin: 10px 0px;
  cursor: pointer;
  width: 100%;
  border: 1px solid var(--Design-Text-Title, #090909);
}

.regBlockClick {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.4px;
  text-align: left;
  color: #807c90;
  text-align: center;
  margin-top: 10px;
}

.regBlockClick span {
  color: #090909;
  cursor: pointer;
}

.IDuploadZona {
  height: 30vh;
  border: 1px dashed black;
  border-radius: 10px;
}

.IDuploadZona input {
  border: none;
}

.inputLogin input {
  width: 100%;
  height: 66px;
  border-radius: 12px;
  border: 1px solid #ceced3;
  padding-left: 20px;
  font-size: 18px;
  transition: 0.3s;
}

.inputLogin input:focus {
  outline: none;
  border: 1px solid #090909;
}

.inputLogin p {
  margin-bottom: 12px;
  margin-top: 8px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #000000;
}

.inputLogin__wallet__header {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.inputLogin__wallet__header .inputLogin__wallet__header_info {
  padding: 16px;
  max-width: 430px;
  width: 100%;
  z-index: 4;
  position: absolute;
  bottom: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  border: 1px solid #f5f4f5;
  background: #fff;
  box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
}

.inputLogin__wallet__header .inputLogin__wallet__header_info img {
  padding: 8px;
  border-radius: 50px;
  background-color: #dcf0db;
}

.inputLogin__wallet__header .inputLogin__wallet__header_info p {
  margin: 0;
  color: #47434d;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.inputLogin__wallet__header .info_icon {
  cursor: help;
}

.inputLogin__wallet__input__container {
  position: relative;
}

.inputLogin__wallet__input__container input {
  padding-right: 30%;
}

.inputLogin__wallet__input__container button {
  position: absolute;
  cursor: pointer;
  right: 16px;
  top: 13px;

  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;

  border-radius: 12px;
  background: #090909;
  padding: 10px;
  border: none;
}

.inputCurrency {
  position: relative;
  width: 100%;
  min-height: 130px;
  padding: 15px;
  border-radius: 24px;
  border: 1px solid #090909;
  padding-bottom: 5px;
}

.inputCurrency h3 {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: #807c90;
  margin: 0px;
}

.inputCurrency .inputCurrency__value input {
  text-align: right;
  font-family: Inter;
  font-size: 48px;
  font-weight: 500;
  height: 66px;
  border: none;
  padding-left: 2px;
  transition: 0.3s;
  width: 100%;
  color: #090909;
  outline: none;
}

.inputCurrency .inputCurrency__value input::placeholder {
  font-family: Inter;
  font-size: 48px;
  font-weight: 400;
  line-height: 20px;
  text-align: right;
}

.inputCurrency input::-webkit-outer-spin-button,
.inputCurrency input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

.inputCurrency img {
  width: 44px;
  height: 44px;
}

.inputCurrency__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.valueCurrancy {
  font-family: Inter;
  font-size: 48px;
  font-weight: 500;
  line-height: 57.6px;
  text-align: left;
  color: #090909;
}

.iconExchange__container {
  height: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.iconExchange {
  z-index: 2;
  height: 36px;
  width: 36px;
}

.iconExchange > img {
  height: 44px;
  width: 44px;
  top: -20px;
  position: relative;
}

.descriptionExchange {
  color: #807c90;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
}

.fee {
  color: #47434d;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
}

.feecount {
  color: #090909;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
}

.exchangeBlock p {
  color: #090909;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.setDatetimeInput p {
  color: #090909;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
}

.setDatetimeInput input {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  height: 66px;
  outline: none;
  padding: 16px;
  width: 100%;
  color: #47434d;
  border: 1px solid #cfced3;
  border-radius: 10px;
  transition: 0.3s;
}

.setDatetimeInput input:focus {
  border: 1px solid #090909;
}

.setDatetimeInput input::placeholder {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.mobile-time-input {
  position: relative;
}

.mobile-time-input > img {
  position: absolute;
  right: 20px;
  top: 24px;
}

.nameAuthUser {
  display: table;
  margin-right: 10px;
}

.nameAuthUserHeader {
  vertical-align: middle;
  display: table-cell;
  padding-right: 10px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  color: #4c4657;
}

.nameAuthUserHeaderShort {
  width: 42px;
  height: 42px;
  background-color: #837be7;
  border-radius: 100%;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 28.8px;
  color: #ffffff;
}

.backButton img {
  height: 15px;
}

.forgotPass__resend-code {
  color: #807c90;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin-top: 16px;
  cursor: pointer;
}

.divider {
  border-top: 1px solid #cfced3;
}

.sumsub-container {
  min-height: 40vh;
}

.inputCurrency__currency__container {
  position: relative;
}

.inputCurrency__currency__input {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px 4px 4px;
  min-width: 132px;
  border-radius: 40px;
  border: 1px solid #e5e4e7;
  background: #f5f4f5;
}

.inputCurrency__currency__input p {
  text-wrap: nowrap;
  font-family: Inter;
  margin: 0;
  color: #090909;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-left: 6px;
}

.inputCurrency__currency__input .inputCurrency__currency__arrow {
  height: 20px;
  width: 20px;
}

.inputCurrency__currency__select {
  z-index: 3;
  position: absolute;
  width: 311px;
  border-radius: 24px;
  border: 1px solid #f5f4f5;
  background: #fff;
  box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
  padding: 16px;
}

.inputCurrency__currency__select .inputCurrency__currency__select__container {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.inputCurrency__currency__select__item {
  width: 100%;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  transition: 0.3s;
}

.inputCurrency__currency__select__item .check-icon {
  width: 20px;
  height: 20px;
  margin-left: auto;
  margin-right: 10px;
}

.inputCurrency__currency__select__item:hover {
  background-color: #f5f4f5;
}

.inputCurrency__currency__select__item p {
  margin: 0;
  overflow: hidden;
  color: #090909;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.inputCurrency__currency__select .inputCurrency__currency__select__input {
  position: relative;
}

.inputCurrency__currency__select
  .inputCurrency__currency__select__input
  .select__input__icon {
  height: 17.5px;
  width: 17.5px;
  position: absolute;
  left: 10px;
  top: 16px;
}

.inputCurrency__currency__select .inputCurrency__currency__select__input input {
  color: #807c90;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding: 13px 16px 13px 30px;
  border-radius: 12px;
  border: 1px solid #cfced3;
  background: #fff;
}
