@media screen and (max-width: 768px) {
  .exchange-history {
    width: 100%;
    max-width: 100vw;
    margin-top: 70px;
  }
}

@media screen and (min-width: 768px) {
  .exchange-history {
    max-width: 465px;
    width: 100%;
  }
}

.exchange-history.open {
  right: 0;
}

.exchange-history h1 {
  margin: 0;
  color: #090909;
  font-family: Inter;
  font-size: 28px;
  font-weight: 700;
  line-height: 120%;
}

.input__container {
  position: relative;
  display: flex;
  align-items: center;
}

.input__container > img {
  position: absolute;
  right: 8px;
  cursor: pointer;
}

.rotate {
  transform: rotate(180deg);
}

@media screen and (max-width: 768px) {
  .exchange-history {
    z-index: 20;
    position: fixed;
    right: -100%;
    transition: 0.5s;
    top: 0;
  }

  .exchange-history__container {
    overflow: auto;
    width: 100%;
    background: #fff;
    padding-bottom: 70px;
    height: 100vh;
  }
}

@media screen and (min-width: 768px) {
  .exchange-history {
    z-index: 6;
    position: fixed;
    top: 12px;
    right: -500px;
    transition: 0.5s;
    bottom: 12px;
  }

  .exchange-history__container {
    overflow: auto;
    border-radius: 34px 0px 0px 34px;
    border: 1px solid #f5f4f5;
    background: #fff;
    max-width: 465px;
    height: 97vh;
    padding: 24px 42px;
    box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
  }

  .exchange-history__container h1 {
    margin-top: 40px;
  }
}

@media screen and (max-width: 768px) {
  .exchange-history__header {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .exchange-history__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.exchange-history__header > img {
  cursor: pointer;
}

/* .exchange-history__container>h1 {
  margin-top: 40px;
} */

.exchange-history__items {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media screen and (max-width: 768px) {
  .exchange-history__items {
    padding-bottom: 120px;
  }
}

.exchange-history__text {
  display: flex;
  align-items: center;
  gap: 12px;
}

.exchange-history__profile {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.exchange__profile-dropdown {
  position: absolute;
  cursor: pointer;
  top: 28px;
  right: 0;

  border-radius: 12px;
  border: 1px solid#F5F4F5;
  background: #fff;
  box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
  padding: 16px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;

  color: #090909;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.exchange-history__title {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.exchange-history__title img {
  cursor: pointer;
}

.exchange-history__details {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

@media screen and (max-width: 768px) {
  .exchange-history__details {
    padding-bottom: 120px;
  }
}

.exchange-history__details__text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.exchange-history__details__text p {
  margin: 0;
}

.exchange-history__details_title {
  text-transform: capitalize;
  color: #807c90;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  line-height: 140%;
}

.exchange-history__details_subtitle {
  text-transform: capitalize;
  color: #090909;
  text-align: left;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.exchange-divider {
  margin-top: 20px;
  border-top: 1px solid #f5f4f5;
}

.exchange-history__filters {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
}

.filter__container input {
  /* position: relative; */
  cursor: pointer;
}

.filter__input {
  color: #47434d;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  outline: none;
  border-radius: 12px;
  border: 1px solid #cfced3;
  background: #fff;
  padding: 11px 18px;
  width: 100%;
  box-sizing: border-box;
}

.filter__options {
  left: 0;
  margin-top: 12px;
  z-index: 6;
  border-radius: 12px;
  border: 1px solid #f5f4f5;
  background: #fff;
  box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.filter__options.date {
  position: absolute;
  left: 20px;
}

.filter__container.status {
  position: relative;
}

.filter__options.status {
  position: absolute;
}

.filter__container.currency {
  position: relative;
}

.filter__options.currency {
  position: absolute;
  right: 0;
  width: 141px;
}

.filter__item {
  cursor: pointer;
  overflow: hidden;
  color: #47434d;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  background: #fff;
}

.filter__item.chosen {
  color: #090909;
}

.exchange-history__time-ago {
  color: #807c90;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 20px;
}

.cross {
  cursor: pointer;
  height: 18px;
  width: 18px;
}
