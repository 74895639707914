.exchange-popup-success {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(9, 9, 9, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.exchange-popup__container {
  position: relative;
  margin: 20px;
  background-color: #ffffff;
  max-width: 500px;
  width: 100%;
  padding: min(5%, 40px);
  border-radius: 24px;
  border: 1px solid #f5f4f5;
  box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.popup-close {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
}

.popup-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 350px;
  width: 100%;
}

.popup-status {
  margin-top: 18px;
  color: #090909;
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 120%;
}

.popup-text {
  /* max-width: 336px;
  width: 100%; */
  color: #47434d;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  line-height: 160%;
}

.popup-row {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.popup-value_row {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}

.popup-value {
  color: #090909;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 120%;
}

.popup-value_row > img {
  width: 32px;
  height: 32px;
}

.popup-info {
  color: #47434d;
  font-family: Inter;
  font-size: 18px;
  line-height: 160%;
  text-align: center;
}

.popup-info_date {
  color: #090909;
  font-family: Inter;
  font-size: 18px;
  line-height: 160%;
}

.popup-button {
  cursor: pointer;
  max-width: 410px;
  width: 100%;
  padding: 20px 0px;
  border: none;
  border-radius: 56px;
  background: #090909;
  box-shadow: 0px 3px 12px 0px rgba(74, 58, 255, 0.18);
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
}

.popup__secpass__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 30px;
  border-radius: 12px;
  background: #edf8ed;
  padding: 16px;
}

.popup__secpass__header,
.popup__wallet__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.popup__secpass__title {
  margin: 0;
  color: #47434d;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 24px */
}

.popup__secpass__title span {
  color: #090909;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.popup__secpass__subtitle {
  margin: 0;
  color: #47434d;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.popup__wallet__title {
  margin: 0;
  color: #47434d;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 24px */
}

.popup__wallet__title span {
  color: #090909;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.popup__line {
  border: 0.1px solid #96d394;
}

.popup__secpass__copy {
  cursor: pointer;
}
