@keyframes rotate-semi-circle {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.rotating-circle {
  width: 66px;
  height: 66px;
  animation: rotate-semi-circle 1s linear infinite;
}