.input-with-label {
}

.input-with-label label {
  color: #090909;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.input-with-label input {
  margin-top: 12px;
  width: 100%;
  height: 66px;
  border-radius: 12px;
  border: 1px solid #ceced3;
  padding-left: 20px;
  font-size: 18px;
  transition: 0.3s;
}

.input-with-label input:disabled {
  background: #faf9fa;
  border: 1px solid #cfced3;
}
