.stepNumber {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #4c4657;
  margin-top: 7px;
}

.stepNumber > p:first-child {
  text-transform: capitalize;
  margin: 0;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #4c4657;
}

.stepNumber > p:last-child {
  margin: 0;
  color: #4c4657;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.stepNumber span {
  color: #a29caf;
}

.StepBlock {
  height: 100%;
  padding-bottom: 0px;
}

.StepBlockHeader {
  border-bottom: 1px solid #e5e4e7;
  padding: 20px 30px;
}

.StepBlockHeaderColumn {
  display: inline-flex;
  flex-direction: column;
}

.StepBlockHeader h2 {
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 35px;
  text-align: left;
  color: #090909;
  margin: 0;
  margin-top: 10px;
}

.StepBlockHeader p {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: #47434d;
  margin: 5px 0px;
}

.StepBlockHeader p:last-child {
  color: #47434d;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.StepBlockMain {
  padding: 20px 40px 20px 40px;
  min-height: 37vh;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.labelText {
  color: #090909;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
  margin-bottom: 12px;
}

.Submit__title {
  margin: 0;
  color: #090909;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 20px;
  margin-top: 30px;
}

.Submit-inputs-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.Submit-inputs-grid__item {
  grid-column: span 2;
}

.StepBlockMain p {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #000000;
}

.submitInputBlock {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.radio-buttons {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
}

.StepBlockMain input {
  width: 100%;
  height: 66px;
  border-radius: 12px;
  border: 1px solid #ceced3;
  padding-left: 20px;
  font-size: 18px;
  transition: 0.3s;
}

.StepBlockMain input:focus {
  outline: none;
  border: 1px solid #090909;
}

.StepBlockMain input::placeholder {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.inputName:disabled {
  background: #faf9fa;
  border: 1px solid #cfced3;
}

.StepBlockMain select {
  cursor: pointer;
  background: white;
  padding: 23px;
  height: 66px;
  border-radius: 12px;
  border: 1px solid #ceced3;
  transition: 0.3s;

  color: #47434d;
  font-family: Inter;
  font-size: 16px;
}

.StepBlockMain select:focus {
  outline: none;
  border: 1px solid #090909;
}

.StepBlockMain select::placeholder {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.submitButtonStepStyle:disabled {
  border-radius: 56px;
  padding: 20px 40px;
  background-color: #f4f4f5;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  color: #ceced3;
  margin: 10px 15px;
  cursor: pointer;
  border: aliceblue;
  box-shadow: none;
}

.submitButtonStepStyle {
  border-radius: 56px;
  padding: 20px 40px;
  background-color: #090909;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  margin: 10px 15px;
  cursor: pointer;
  border: aliceblue;
  box-shadow: 0px 3px 12px 0px #4343432e;
}

.editButtonStepStyle {
  cursor: pointer;
  background-color: #fff;
  padding: 20px 40px;
  border-radius: 56px;
  border: 1px solid #090909;
  color: #090909;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
}

.StepBlockMain li {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  margin-bottom: 10px;
}

.StepBlockMain li::marker {
  font-size: 0.7em;
}

.IDuploadZona {
  height: 30vh;
  border: 1px dashed black;
  border-radius: 10px;
}

.IDuploadZona input {
  border: none;
}

.privacy-policy__link {
  cursor: pointer;
  color: #576bf9;
  font-size: 18px;
  font-weight: 500;
  text-decoration-line: underline;
}

.incorrect-code {
  color: #f00;
  font-size: 12px;
  font-weight: 500;
}

.code-sent {
  color: #0a990e;
  font-size: 12px;
  font-weight: 500;
}

.success {
  color: #0a990e;
  font-size: 18px;
  font-weight: 700;
}

.resend-code {
  cursor: pointer;
  margin-top: 20px;
  color: #807c90;
  font-size: 18px;
  font-weight: 500;
  text-decoration-line: underline;
}

.checking-code {
  margin-top: 16px;
  color: #090909;
  font-size: 18px;
  font-weight: 700;
}

.edit-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 2px;
}

.edit-text {
  cursor: pointer;
  color: #807c90;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  text-decoration-line: underline;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 768px) {
  .StepBlockHeader p {
    font-size: 16px;
  }

  .StepBlockHeader {
    border: none;
    padding: 20px 30px 0px 30px;
  }

  .submitButtonStepStyle {
    width: 100%;
    margin-top: 40px !important;
  }

  .StepBlockMain {
    min-height: 50px;
  }
}

.password-input {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.password-checks__title {
  margin: 0;
  margin-top: 6px;
  color: #090909;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.password-checks {
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.password-checks .password-check {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.password-checks .password-check p {
  color: #807c90;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0;
}

.password-input > img {
  cursor: pointer;
  position: absolute;
  right: 16px;
}

.country-input__container {
  position: relative;
}

.country-input__container > img {
  position: absolute;
  right: 10px;
  top: 25px;
}

.arrow-normal {
  cursor: pointer;
}

.arrow-rotate {
  cursor: pointer;
  transform: rotate(3.142rad);
}

@media screen and (max-width: 768px) {
  .id__info-text {
    display: none;
  }
}

.error {
  color: #f00;
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  line-height: 140%;
}

.input-mask__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.input-mask {
  width: 100%;
}

.pac-container {
  width: 100%;
  position: absolute;
  margin-top: 12px;
  z-index: 2;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 12px;
  padding: 16px;
  max-height: 264px;
  border: 1px solid #f5f4f5;
  box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
  border: none;
  font-family: Inter;
  font-size: 18px;
}

.pac-item-query {
  font-size: 18px;
}

.pac-item {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px;
  border: none;
  overflow: hidden;

  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.pac-icon-marker {
  display: none;
}

.pac-logo {
  font-size: 18px;
  /* color: red;
    background-color: red; */
}

.personal__bottom-text {
  color: #807c90;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin-left: 15px;
}

.step__input__title {
  margin-bottom: 12px;
}

@media screen and (max-width: 768px) {
  .step__input__title {
    margin-top: 20px;
  }

  .step__input__title_last-name {
    margin-top: 20px;
    margin-bottom: 12px;
  }

  .step__input__title_first-name {
    margin-top: 0px;
  }
}

@media screen and (min-width: 768px) {
  .step__input__title {
    margin-top: 30px;
  }

  .step__input__title_last-name,
  .step__input__title_first-name {
    margin-top: 0;
    margin-bottom: 12px;
  }
}
