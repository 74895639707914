.qr-open-button__wrapper {
  z-index: 3;
  position: fixed;
  bottom: 120px;
  right: 5%;
  border-radius: 40px;
  border: 4px solid rgba(24, 131, 255, 0.2);
}

.qr-open-button__wrapper .qr-open-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-items: center;
  padding: 13px;
  border-radius: 40px;
  background: #1883ff;
}
