.menuBlock {
  height: 10vh;
}

.elementMenu {
  font-family: Inter;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  cursor: pointer;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 768px) {
  .menuBlock {
    height: 7vh;
  }
}

.menu-history {
  position: relative;
  display: flex;
  align-items: center;
}

.menu-history__text {
  cursor: pointer;
  margin: 0;
  color: #47434d;
  text-align: right;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* margin-right: 30px; */
}

.menu-history__counter {
  position: absolute;
  background-color: #1883ff;
  color: #fff;
  font-family: Inter;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .menu-history__counter {
    top: 6px;
    right: -20px;
  }
}

@media screen and (max-width: 768px) {
  .menu-history__counter {
    top: 2px;
    right: -6px;
  }
}

.profile-dropdown {
  cursor: pointer;
  z-index: 25;
  position: absolute;
  top: 46px;
  right: 0;

  border-radius: 12px;
  border: 1px solid#F5F4F5;
  background: #fff;
  box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
  padding: 16px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;

  color: #090909;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.nameAuthUserHeaderShort__container {
  border-left: 1px solid #cfced3;
  padding-left: 12px;
  margin-left: 30px;
}

.lang__container {
  margin-left: 12px;
  z-index: 5;
  display: flex;
  align-items: center;
  position: relative;
}

.lang__container .lang__container__button {
  cursor: pointer;
}

.lang__container .lang__container__languages {
  display: flex;
  flex-direction: column;
  gap: 14px;
  position: absolute;
  right: 0;
  top: 40px;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #f5f4f5;
  background: #fff;
  box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
}

.lang__container .lang__container__languages .lang__container__item {
  border: none;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 4px;
  padding: 4px 16px;
}

.lang__container .lang__container__languages .lang__container__item[disabled] {
  background-color: #f5f4f5;
}

.lang__container .lang__container__languages .lang__container__item:hover {
  background-color: #f5f4f5;
}

.lang__container .lang__container__languages .lang__container__item p {
  margin: 0;
  color: #47434d;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
