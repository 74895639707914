.radio-button__label {
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.radio-button__label input {
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.radio-button__label span {
  text-transform: capitalize;
  color: #47434d;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
