.phone-list__container {
  outline: none;
  background: #FFF;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 12px;
  padding: 16px 0px;
  max-height: 264px;
  border: 1px solid #F5F4F5;
  box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
}