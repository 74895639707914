.elementStep {
  display: flex;
  justify-content: center;
}
.numberElementStep {
  width: 38px;
  height: 38px;
  border-radius: 100%;
  background-color: #f4f5f5;
  justify-content: center;
  display: flex;
  align-items: center;
  z-index: 2;
}
.activeNumberElementStep {
  background-color: black !important;
}
.activeNumberElementStep span {
  color: white !important;
}
.lastNumberElementStep {
  background-color: #ceced3 !important;
}
.lastNumberElementStep span {
  color: white !important;
}
.numberElementStep span {
  color: #ced2d3;
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
}
.textElementSpan {
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: #b4b8c2;
}
.activeTextElementSpan {
  color: black !important;
}
.greyTextElementSpan {
  color: #8f8e9b !important;
}
.lineElementStep {
  height: 32px;
  justify-content: center;
  display: flex;
}

.lineElementStep div {
  width: 1px;
  height: 100%;
  background-color: #ced2d3;
  z-index: 1;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 768px) {
  .mobilePointer .elementStep {
    display: flex;
    width: auto;
    display: inline-block;
  }
  .mobilePointer {
    width: max-content;
    overflow-x: scroll;
  }
  .mobilePointer .textElementSpan {
    margin: 5px 10px;
  }
  .elementStep {
    cursor: pointer;
  }
}
