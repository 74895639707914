.buttonMenu {
  border: 2px solid black;
  padding: 12px 30px;
  border-radius: 22px;
  display: inline;
  font-family: Inter;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  cursor: pointer;
  letter-spacing: -0.528px;
  text-align: center;
}
