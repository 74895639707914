.logo {
  font-family: Inter;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo img {
  width: 100%;
  cursor: pointer;
}
